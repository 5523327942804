import { ReactElement, useState, useEffect } from 'react';
import { uniqBy } from 'lodash-es';
import {
  Grant, Leader, ZoomSettings, ZoomUserContactsSearchResult, ConferenceContact, ZoomSettingsUpdate,
  FetchReturn, ZoomSettingsResponse, LeaderList,
} from '../../../store';
import {
  InputLabel, FormControl, FormHelperText, Typography, DialogContentText, Grid, useMediaQuery, Box,
  Link, FormLabel, Alert, AlertTitle, SelectChangeEvent,
} from '@mui/material';
import {
  ZOOM_SHARED_ACCESS_PERMISSIONS, ZOOM_SHARED_ACCESS_PERMISSIONS_UPDATE, ZOOM_SIGN_IN_BUTTON,
} from '../../../resourceUrls';
import { Link as RouterLink } from 'react-router-dom';
import classes from '../SignIn.module.css';
import { useDebouncedCallback } from 'use-debounce';
import { LEARN_MORE_ZOOM, PAGE_URL, PROVIDER, ZOOM_CONNECT_STRATEGY } from '../../../constants';
import {
  CabButton, CabAutocomplete, CabDropdown, CabControlLabel, CabSwitch, CabTooltip, CabModal,
  CabIcon
} from '@CabComponents';
import theme from '../../../theme';
import { colors } from '../../../colors';
import { useCabinetText } from '../../../CabinetContext';
import { ZoomSettingsError } from '../../../store/schedule/actions';
import CabSpinner from '@CabComponents/CabSpinner';
import { IoInformationCircleOutline } from 'react-icons/io5';


interface Props {
  grant?: Grant;
  hidePMIToggle?: boolean;
  isOnboarding?: boolean;
  leaders: LeaderList;
  zoomAccountsInfo: { [oauthTokenId: number]: ZoomAccountInfo };
  zoomSettings: { [settingsId: string]: ZoomSettings };
  getZoomContacts: (grant: Grant, searchStr: string) => Promise<ZoomUserContactsSearchResult>;
  updateZoomSettings: (conferenceSettings: ZoomSettingsUpdate) => Promise<FetchReturn<ZoomSettingsResponse, {
    error: ZoomSettingsError;
  }> | undefined>;
  onSubmitZoomExecs: (settings: { [leaderId: number]: ZoomExecData; }) => Promise<void>;
  onGrantOfflineClick: () => void;
  hasBasicAccess: boolean;
  useVerticalLayout?: boolean;
  userLeader: number;
}

export interface ZoomAccountInfo {
  username: string;
  users: {
    id: string;
    email: string;
  }[];
  hasBasicAccess: boolean;
}

export type ZoomExecData = {
  id?: ZoomSettings['id'];
  alternate_hosts: ConferenceContact[];
  schedule_obo?: ConferenceContact | null;
  personal_meeting_link?: string;
  method: ZOOM_CONNECT_STRATEGY;
  conference_provider: number;
  oauth_token?: {
    id: number;
    username: string;
    provider: string;
  };
  leader: number;
  is_shared: boolean;
  order: number;
};

const PmiTooltip = ({ children }: { children: ReactElement }) => (
  <CabTooltip
    wrapWithSpan
    title={"Turning this on will use Personal Meeting Rooms instead of unique links for each meeting."}
    placement="top-start"
  >
    {children}
  </CabTooltip>
);

const ZoomExecSettings = ({ 
  leader, setting, onChange, grant, grantOauthTokenId, getZoomContacts, hidePMIToggle = true,
  onUpdateZoomSettings, accountEditable, zoomSchedulerInfo, useVerticalLayout, userLeader
}: {
  leader: Leader;
  setting: ZoomExecData;
  zoomSchedulerInfo: { [oauthTokenId: number]: ZoomAccountInfo };
  onChange: (settings: ZoomExecData) => void;
  onUpdateZoomSettings: (
    conferenceSettings: ZoomSettingsUpdate
  ) => Promise<FetchReturn<ZoomSettingsResponse, { error: ZoomSettingsError }> | undefined>;
  grant?: Grant;
  grantOauthTokenId?: number;
  getZoomContacts: (grant: Grant, searchStr: string) => Promise<ZoomUserContactsSearchResult>;
  accountEditable: boolean;
  hidePMIToggle?: boolean;
  useVerticalLayout?: boolean;
  userLeader: number;
}) => {
  const [zoomContacts, setZoomContacts] = useState<ConferenceContact[]>([]);
  const [loadingContacts, setLoadingContacts] = useState<boolean>(false);
  const [showZoomAccountAssociationModal, setShowZoomAccountAssociationModal] = useState(false);
  const [zoomAccountOauthTokenId, setZoomAccountOauthTokenId] = useState(setting.oauth_token?.id);

  useEffect(() => {
    setZoomAccountOauthTokenId(setting.oauth_token?.id);
  }, [setting.oauth_token?.id, setZoomAccountOauthTokenId]);

  const debouncedGetZoomContacts = useDebouncedCallback(async (curGrant: Grant, searchStr: string) => {
    if (searchStr) {
      const res = await getZoomContacts(curGrant, searchStr);
      setZoomContacts(
        res.zoom_user_contacts.map(c => ({
          account_user_id: c.id,
          username: c.email,
          first_name: c.first_name,
          last_name: c.last_name,
        })) || []
      );
    } else {
      setZoomContacts([]);
    }
    setLoadingContacts(false);
  }, 500);

  const zoomAuthValid = !!grant;
  const tooltipTitle = (leader.is_shared && !accountEditable)
    ? "You can't change Zoom settings for a shared colleague." 
    : !zoomAuthValid
      ? "Your Zoom account is not properly connected - see above."
      : "";

  // A bit hacky, but if there exists a schedule_obo object that has no account_user_id, it means
  //   the tokens behind it are not valid, so we use -2 to make sure the dropdown shows empty (tooltip explains).
  //   If schedule_obo is null, that just means we should use the root tokens, thus -1 shows the root token option.
  const zoomOboValue = setting.schedule_obo 
    ? setting.schedule_obo.account_user_id !== ''
      ? JSON.stringify({ zoomTokenId: setting.oauth_token?.id, zoomUserId: setting.schedule_obo.account_user_id })
      : '-2' 
    : JSON.stringify({ zoomTokenId: setting.oauth_token?.id, zoomUserId: '-1' });

  const tooltipError = zoomOboValue === "-2" ? "The Zoom account for this shared colleague is disconnected." : null;

  let zoomAccountOptions = setting.oauth_token
    ? [{ value: setting.oauth_token.id, label: setting.oauth_token?.username }]
    : [];

  if (grantOauthTokenId && grant) {
    zoomAccountOptions = uniqBy([
      ...zoomAccountOptions,
      { value: grantOauthTokenId, label: grant?.username },
    ], 'value');
  }

  // const zoomUserOptions = setting.oauth_token?.id
  //   ? (allZoomAccountUsers[setting.oauth_token?.id]?.users.map(u => ({ value: u.id, label: u.email })) || [])
  //   : [{ value: '-1', label: grant?.username ?? ""}];
  // const zoomUserOptions = zoomSchedulerInfo?.users.map(zu => ({ value: zu.id, label: zu.email })) || [];
  const zoomUserOptions = Object.entries(zoomSchedulerInfo)
    .flatMap(([zoomTokenId, zsi]) => ([
      { groupHeader: zsi.username },
      ...zsi.users.map(zu => ({
        value: JSON.stringify({ zoomTokenId: Number(zoomTokenId), zoomUserId: zu.id }),
        label: zu.email 
      }))
    ]));

  // This will add options to the list of Zoom settings for shared executives. They must be present to be
  //   shown in the dropdowns, but should not be selectable since the user does not have correct tokens for them.
  if (setting.schedule_obo && setting.schedule_obo.account_user_id !== "") {
    if (!zoomUserOptions.find(option => {
      if ('value' in option) {
        const value = JSON.parse(option.value);
        return (
          value.zoomUserId === setting.schedule_obo?.account_user_id
          && value.zoomTokenId === setting.oauth_token?.id
        );
      }
      return false;
    })) {
      zoomUserOptions.push({
        value: JSON.stringify({
          zoomTokenId: setting.oauth_token?.id,
          zoomUserId: setting.schedule_obo.account_user_id,
        }),
        label: setting.schedule_obo.username,
        // shared: true,
      });
    }
  }


  const handleChangeZoomAccount = async () => {
    if (setting.id && zoomAccountOauthTokenId) {
      await onUpdateZoomSettings({ id: setting.id, oauth_token_id: Number(zoomAccountOauthTokenId) });
    }
    setShowZoomAccountAssociationModal(false);
  };

  const handleCancelChangeZoomAccount = () => {
    setZoomAccountOauthTokenId(setting.oauth_token?.id);
    setShowZoomAccountAssociationModal(false);
  };

  const handleChangeZoomUser = (event: SelectChangeEvent<string>) => {
    const val = event.target.value as (string|undefined);
    const zoomUser = zoomUserOptions.find(zu => 'value' in zu && zu.value === val);

    let settingsUpdate: Partial<ZoomExecData>  = { schedule_obo: null };
    if (zoomUser && 'value' in zoomUser) {
      const { zoomTokenId, zoomUserId } = JSON.parse(zoomUser.value);
      settingsUpdate = {
        // make sure we never send these values in a request
        schedule_obo: zoomUserId === '-1' || zoomUserId === '-2'
          ? null
          : { account_user_id: zoomUserId, username: zoomUser.label },
        oauth_token: {
          id: zoomTokenId,
          username: zoomSchedulerInfo[zoomTokenId].username,
          provider: PROVIDER.ZOOM.name,
        },
      };
    }
    onChange({
      ...setting,
      ...settingsUpdate,
    });
  };

  const zoomAccount = zoomAccountOptions.find(za => za.value === zoomAccountOauthTokenId);
  const zoomUserSelectDisabled = !zoomAuthValid || (leader.is_shared && !accountEditable);
  return (
    <>
      {!useVerticalLayout ? (
        <Grid container rowSpacing={1} columnSpacing={3} marginBottom={{xs: 3, sm: 1, md: 1}} 
          sx={{ display: 'flex', alignItems: 'start' }}
        >
          <Grid item xs={6} sm={12} md={2.5} lg={3}>
            <Typography noWrap variant="subtitle1" className={classes.execName} 
              marginBottom={{xs: "4px", sm: "4px", md: 0}} marginTop={1}>
              {leader.first_name} {leader.last_name}
            </Typography>
          </Grid>
          {!hidePMIToggle && (
            <Grid item xs={6} md={"auto"}>
              <PmiTooltip>
                <CabControlLabel
                  label='Use PMI'
                  labelPlacement='end'
                  control={<CabSwitch
                    checked={setting.method === ZOOM_CONNECT_STRATEGY.PMI}
                    onChange={event => {
                      const val = event.target.checked ? ZOOM_CONNECT_STRATEGY.PMI : ZOOM_CONNECT_STRATEGY.OBO;
                      onChange({ ...setting, method: val });
                    }}
                    disabled={!zoomAuthValid || leader.is_shared}
                  />}
                />
              </PmiTooltip>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4.5} lg={4}>
            <Box display="flex" flexDirection="column">
              <CabTooltip title={zoomUserSelectDisabled && tooltipError ? tooltipTitle : undefined}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel>Select Zoom user</InputLabel>
                  <CabDropdown<string>
                    disabled={zoomUserSelectDisabled}
                    label="Select Zoom user"
                    value={zoomOboValue}
                    size='medium'
                    onChange={handleChangeZoomUser}
                    sx={{ minWidth: "200px", width: "100%" }}
                    options={zoomUserOptions}
                    hideOptionTooltipsWhenClosed
                  />
                </FormControl>
              </CabTooltip>
              {leader.is_shared && zoomAccountOptions.length > 1 && (
                <CabTooltip title="Zoom user account where user options come from" wrapWithSpan placement="top">
                  <Box display="flex" flexDirection="row" alignContent="center" flexWrap="wrap">
                    <FormHelperText sx={{marginLeft: 0.5,  marginRight: 0.5, fontSize: 12 }}>
                      Zoom Account: <b>{zoomAccount?.label || 'None'}</b>
                    </FormHelperText>
                  </Box>
                </CabTooltip>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={5}>
            <CabTooltip title={zoomUserSelectDisabled && tooltipError ? tooltipTitle : undefined}>
              <FormControl sx={{position: "relative", width: "100%"}}>
                <ZoomAlternativeHosts
                  currentAlternateHosts={setting.alternate_hosts}
                  contacts={zoomContacts}
                  searchContacts={(str) => {
                    if (!zoomAuthValid || !grant) return;
                    setLoadingContacts(true);
                    debouncedGetZoomContacts(grant, str);
                  }}
                  onSubmit={altHosts => {
                    onChange({ ...setting, alternate_hosts: altHosts });
                  }}
                  loading={loadingContacts}
                  submitting={false}
                  disabled={zoomUserSelectDisabled}
                />
              </FormControl>
            </CabTooltip>
          </Grid>
        </Grid>
      ) : (
        <Box display='flex' flexDirection='column' gap={2}>
          <Box display='flex' gap={.5}>
            <Typography noWrap variant="body2" fontWeight={700}>
              {leader.first_name} {leader.last_name}
            </Typography>
            {leader.id === userLeader && (
              <Typography noWrap variant="body2" fontWeight={700}>
                (You)
              </Typography>
            )}
          </Box>
          <Box display='flex' gap={2}>
            {!hidePMIToggle && (
              <Box>
                <PmiTooltip>
                  <CabControlLabel
                    label='Use PMI'
                    labelPlacement='end'
                    control={<CabSwitch
                      checked={setting.method === ZOOM_CONNECT_STRATEGY.PMI}
                      onChange={event => {
                        const val = event.target.checked ? ZOOM_CONNECT_STRATEGY.PMI : ZOOM_CONNECT_STRATEGY.OBO;
                        onChange({ ...setting, method: val });
                      }}
                      disabled={!zoomAuthValid || leader.is_shared}
                    />}
                  />
                </PmiTooltip>
              </Box>
            )}
            <Box display='flex'>
              <Box display="flex" flexDirection="column">
                <CabTooltip title={zoomUserSelectDisabled && tooltipError ? tooltipTitle : undefined}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>Select Zoom user</FormLabel>
                    <CabDropdown<string>
                      disabled={zoomUserSelectDisabled}
                      value={zoomOboValue}
                      placeholder='Select user'
                      size='medium'
                      onChange={handleChangeZoomUser}
                      sx={{ minWidth: "200px", width: "275px" }}
                      options={zoomUserOptions}
                      hideOptionTooltipsWhenClosed
                    />
                  </FormControl>
                </CabTooltip>
                {leader.is_shared && zoomAccountOptions.length > 1 && (
                  <CabTooltip title="Zoom user account where user options come from" wrapWithSpan placement="top">
                    <Box display="flex" flexDirection="row" alignContent="center" flexWrap="wrap">
                      <FormHelperText sx={{marginLeft: 0.5,  marginRight: 0.5, fontSize: 12 }}>
                        Zoom Account: <b>{zoomAccount?.label || 'None'}</b>
                      </FormHelperText>
                    </Box>
                  </CabTooltip>
                )}
              </Box>
            </Box>
            <Box display='flex'>
              <CabTooltip title={zoomUserSelectDisabled && tooltipError ? tooltipTitle : undefined}>
                <FormControl sx={{position: "relative", width: "275px", minWidth: '200px'}}>
                  <FormLabel>Alternative Hosts</FormLabel>
                  <ZoomAlternativeHosts
                    currentAlternateHosts={setting.alternate_hosts}
                    contacts={zoomContacts}
                    searchContacts={(str) => {
                      if (!zoomAuthValid || !grant) return;
                      setLoadingContacts(true);
                      debouncedGetZoomContacts(grant, str);
                    }}
                    onSubmit={altHosts => {
                      onChange({ ...setting, alternate_hosts: altHosts });
                    }}
                    loading={loadingContacts}
                    submitting={false}
                    disabled={zoomUserSelectDisabled}
                  />
                </FormControl>
              </CabTooltip>
            </Box>
          </Box>
        </Box>
      )}
      <CabModal
        open={showZoomAccountAssociationModal}
        title={'Zoom Account'}
        text="This will change the Zoom account that provides the Zoom user options you can associate with a teammate."
        actionButtons={
          <>
            <CabButton
              buttonType="tertiary"
              onClick={handleCancelChangeZoomAccount}
            >
              Cancel
            </CabButton>
            <CabButton onClick={handleChangeZoomAccount}>
              Update
            </CabButton>
          </>
        }
      >
        <CabDropdown
          sx={{ width: '100%', marginTop: 2 }}
          value={zoomAccountOauthTokenId || ''}
          options={zoomAccountOptions}
          onChange={e => setZoomAccountOauthTokenId(e.target.value as number)}
        />
      </CabModal>
    </>
  );
};

const ZoomAlternativeHosts = ({
  currentAlternateHosts, contacts, searchContacts, onSubmit, loading, submitting, disabled
}: {
  currentAlternateHosts: ConferenceContact[];
  contacts: ConferenceContact[];
  searchContacts: (search: string) => void;
  onSubmit: (contacts: ConferenceContact[]) => void;
  loading: boolean;
  submitting: boolean;
  disabled?: boolean;
}) => {
  const [noOptions, setNoOptions] = useState('Start typing to find alternative hosts');
  const currentAlternateHostIds = currentAlternateHosts.map(c => c.account_user_id);
  const options = uniqBy([...contacts, ...currentAlternateHosts], c => c.account_user_id)
    .map(c => ({ value: c, label: `${c.first_name} ${c.last_name} (${c.username})` }));

  return (
    <div className={classes.alternateHosts}>
      <CabAutocomplete<string, never>
        loading={loading}
        noOptionsText={noOptions}
        value={currentAlternateHostIds}
        onInputChange={(v) => {
          if (v.length === 0) {
            setNoOptions('Start typing to find alternative hosts');
          } else {
            setNoOptions('There are no matching Zoom users at your organization');
          }
          searchContacts(v);
        }}
        onChange={val => Array.isArray(val) && onSubmit(
          val.map(v => options.find(o => v === o.value.account_user_id)?.value)
            .filter((v): v is ConferenceContact => !!v) || []
        )}
        placeholder="Alternative Hosts"
        multiple={true}
        options={options.map(o => ({ value: o.value.account_user_id, label: o.label }))}
        hideArrow
        disabled={submitting || disabled}
      />

      <FormHelperText sx={{marginLeft: "4px",  marginRight: "4px"}}>
        Name or email (must be licensed Zoom user)
      </FormHelperText>
    </div>
  );
};

const leaderSortFunc = (a: ZoomExecData, b: ZoomExecData): number => {
  return a.order - b.order;
};

const ZoomSignIn = ({
  getZoomContacts, zoomAccountsInfo, zoomSettings, updateZoomSettings, grant, leaders, hidePMIToggle,
  isOnboarding, onGrantOfflineClick, hasBasicAccess, useVerticalLayout, onSubmitZoomExecs, userLeader
}: Props): ReactElement => {
  const [submittingContacts, setSubmittingContacts] = useState<boolean>(false);
  const [zoomLeaderSettings, setZoomLeaderSettings] = useState<{ [leaderId: number]: ZoomExecData }>({});
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);
  const [discardButtonEnabled, setDiscardButtonEnabled] = useState<boolean>(false);
  const [showSharedAccessDialog, setShowSharedAccessDialog] = useState<boolean>(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [zoomTitle, zoomHelp] = useCabinetText(['integrations-zoom-title', 'integrations-zoom-help']);

  // TODO: this should be found by using the grant id
  const leaderMap = leaders.leaders.map(leader => ({ [leader.id]: leader }))
    .reduce((curLeaders, l) => ({ ...curLeaders, ...l }), {});
  const leaderConferenceSettings = Object.values(zoomSettings).filter(setting => setting.leader);

  const leaderData: { [leaderId: number]: ZoomExecData } = {};
  leaders.leaders
    .forEach(leader => {
      const foundSettings = leaderConferenceSettings.find(stg => stg.leader === leader.id);
      if (foundSettings) {
        leaderData[leader.id] = {...foundSettings, leader: leader.id, is_shared: leader.is_shared, order: leader.order};
      } else {
        leaderData[leader.id] = {
          conference_provider: 3,
          // oauth_token: {
          //   username: grant.username || '',
          //   provider: grant.provider || '',
          // },
          leader: leader.id,
          method: ZOOM_CONNECT_STRATEGY.OBO,
          schedule_obo: null,
          alternate_hosts: [],
          is_shared: leader.is_shared,
          order: leader.order
        };
      }
    });

  useEffect(() => {
    setZoomLeaderSettings(leaderData);
    setSaveButtonEnabled(false);
    setDiscardButtonEnabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(leaderData)]);

  const handleSubmitZoomExecs = async (settings: { [leaderId: number]: ZoomExecData }) => {
    setSubmittingContacts(true);

    // make sure -1 and -2 values are translated to schedule_obo = null
    const newSettings: { [leaderId: number]: ZoomExecData } = {};
    Object.entries(settings).forEach(([key, data]) => {
      const leaderIdKey = Number(key);
      newSettings[leaderIdKey] = { ...data };
      const accountUserId = newSettings[leaderIdKey].schedule_obo?.account_user_id;
      if (accountUserId != null && ['-1', '-2'].includes(accountUserId)
      ) {
        newSettings[leaderIdKey].schedule_obo = null;
      }
    });

    await onSubmitZoomExecs(newSettings);

    setSubmittingContacts(false);
  };

  const handleResetZoomSettings = () => {
    setZoomLeaderSettings(leaderData);
    setSaveButtonEnabled(false);
    setDiscardButtonEnabled(false);
  };

  const showZoomSettings = isOnboarding ? !!grant : true;

  const filteredZoomLeaderSettings = Object.values(zoomLeaderSettings)
    .sort(leaderSortFunc).filter(setting => leaderMap[setting.leader]);

  const grantOauthTokenId = Object.values(zoomSettings).find(stg => stg.leader === null)?.oauth_token?.id;

  return (
    <>
      <div className={classes.signInContainer}>
        {!grant && (
          !useVerticalLayout ? (
            <>
              <div className={classes.inputContainer}>
                <p className={classes.connectText}>Connect <b>Zoom</b></p>
                <input
                  className={classes.signInButton}
                  alt='Sign in with Zoom'
                  type='image'
                  id='ZoomSignIn-log-in-btn'
                  src={ZOOM_SIGN_IN_BUTTON}
                  onClick={() => setShowSharedAccessDialog(true)}
                />
              </div>
            </>
          ) : (
            <Box display='flex' flexDirection='column' gap={1}>
              <Box display='flex' flexDirection='column'>
                <Typography variant='h4' fontWeight={500}>Connect Zoom</Typography>
                {!isOnboarding && (
                  <Typography variant='body1' color={colors.black700}>
                    This can be updated at any time on the integrations settings page.
                  </Typography>
                )}
              </Box>
              <CabButton
                onClick={() => setShowSharedAccessDialog(true)}
                buttonType="tertiary"
                color="accent"
                endIcon={ <img src={ZOOM_SIGN_IN_BUTTON} height={18} style={{marginLeft: -9,}} alt="Zoom" />}
                sx={{ 
                  height: 48, color: colors.black900, maxWidth: 400,
                  border: 1.5, borderColor: colors.black200,
                  '& .MuiButton-endIcon': {
                    mixBlendMode: 'multiply'
                  }
                }}
              >
                Sign in with
              </CabButton>
            </Box>
          )
        )}

        {grant && !hasBasicAccess && (
          <Alert severity='error'  sx={{maxWidth: 570, marginTop: 2}}>
            <AlertTitle>Error</AlertTitle>
            <Typography variant="body1">
              {"You might not have the permissions you need from Zoom. Try logging out and back in to fix it. "}
              {"You can send also send an e-mail to "}
              <a href="mailto:support@joincabinet.com">support@joincabinet.com</a>
              {" for help."}
            </Typography>
          </Alert>
        )}

        {showZoomSettings && (
          <div className={classes.zoomExecSettingsContainer}>
            <form className={classes.execSettingsForm} onSubmit={e => {
              e.preventDefault();
              handleSubmitZoomExecs(zoomLeaderSettings);
            }}>
              <Box marginTop={4}>
                <Typography variant='h4'>{zoomTitle}:</Typography>
              </Box>

              {!isOnboarding && (
                <div className={classes.conferenceDescription}>
                  <Typography variant="body1">{zoomHelp}</Typography>
                </div>
              )}

              <Box marginTop={1} marginBottom={2}>
                <Typography variant="body1" color={colors.black700} maxWidth={isOnboarding ? 570 : undefined}>
                  {`This will determine whether Cabinet will create a `}
                  {` unique meeting or use a Personal Meeting Room for each event. `}
                  <Link href={LEARN_MORE_ZOOM} color={colors.greenPrimary} target={'_blank'} fontWeight={700}
                    sx={{textDecoration: 'none'}}>
                    Learn More
                  </Link>
                </Typography>

                <Box marginTop={1}>
                  <CabButton
                    size="small"
                    buttonType="text"
                    onClick={() => setShowSharedAccessDialog(true)}
                    icon={<CabIcon Icon={IoInformationCircleOutline} />}
                  >
                    Troubleshoot
                  </CabButton>
                </Box>
              </Box>



              <div className={classes.execAssociationForm}>
                {filteredZoomLeaderSettings.map(setting => {
                  const zoomSchedulerInfo = setting.oauth_token && zoomAccountsInfo[setting.oauth_token.id]
                    ? { [setting.oauth_token.id]: zoomAccountsInfo[setting.oauth_token.id] } : {};
                  // always include current user's zoom info
                  if (grantOauthTokenId != null
                    && !zoomSchedulerInfo[grantOauthTokenId]
                    && zoomAccountsInfo[grantOauthTokenId]
                  ) {
                    zoomSchedulerInfo[grantOauthTokenId] = zoomAccountsInfo[grantOauthTokenId];
                  }

                  return (
                    <ZoomExecSettings
                      key={setting.leader}
                      leader={leaderMap[setting.leader]}
                      grant={grant}
                      grantOauthTokenId={grantOauthTokenId}
                      setting={setting}
                      zoomSchedulerInfo={zoomSchedulerInfo}
                      onUpdateZoomSettings={updateZoomSettings}
                      useVerticalLayout={useVerticalLayout}
                      userLeader={userLeader}
                      onChange={zoomExecData => {
                        const newZoomExecs = { ...zoomLeaderSettings, [setting.leader as number]: zoomExecData };
                        setZoomLeaderSettings(newZoomExecs);
                        if (JSON.stringify(newZoomExecs) !== JSON.stringify(leaderData)) {
                          setSaveButtonEnabled(true);
                          setDiscardButtonEnabled(true);
                        } else {
                          setSaveButtonEnabled(false);
                          setDiscardButtonEnabled(false);
                        }
                      }}
                      accountEditable={
                        !leaderMap[setting.leader].is_shared
                        || leaderMap[setting.leader].permissions.is_admin
                      }
                      getZoomContacts={getZoomContacts}
                      hidePMIToggle={hidePMIToggle}
                    />
                  );
                })}
              </div>
              <Box display="flex" gap={1} marginTop={4} width='100%' maxWidth={isOnboarding ? 570 : undefined} 
                justifyContent={'flex-end'}>
                {!submittingContacts && (
                  <CabButton buttonType="tertiary" disabled={!discardButtonEnabled} onClick={handleResetZoomSettings} 
                    sx={{ height: 30 }}>
                    {isSmUp ? 'Discard Changes' : 'Discard'}
                  </CabButton>
                )}
                <CabButton type="submit" disabled={!saveButtonEnabled} sx={{ height: 30 }}>
                  {submittingContacts
                    ? <CabSpinner scale={1} sx={{display: 'flex', justifyContent: 'center'}}/>
                    : isSmUp ? 'Save Executive Settings' : 'Save'}
                </CabButton>
              </Box>
            </form>
          </div>
        )}
        {!isOnboarding && (
          <Box display="flex" marginTop={2} marginBottom={1}>
            <Typography variant="body1">To configure additional locations for your meetings,&nbsp;</Typography>
            <Link component={RouterLink} to={PAGE_URL.PREFERENCES} variant="body1" 
              color={colors.black900} fontWeight={600}
            >
              click here
            </Link>
          </Box>
        )}
      </div>

      <CabModal
        open={showSharedAccessDialog}
        onClose={() => setShowSharedAccessDialog(false)}
        title={"Shared Access Permissions"}
        actionButtons={<>
          <CabButton buttonType="tertiary" color="primary" onClick={() => setShowSharedAccessDialog(false)}>
            Cancel
          </CabButton>
          <CabButton
            buttonType="primary"
            onClick={grant ? (() => setShowSharedAccessDialog(false)) : onGrantOfflineClick}
            overrides={{autoFocus: true}}
          >
            {grant ? 'Done' : 'Log in with Zoom'}
          </CabButton>
        </>}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <DialogContentText>
            In order to schedule Zoom meetings on behalf of your executives using&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.zoom.us/hc/en-us/articles/201362803-Scheduling-privilege"
            >
              Zoom Schedulers
            </a>,
            Cabinet needs&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.zoom.us/hc/en-us/articles/4413265586189"
            >
              shared access permissions
            </a>
            &nbsp;to be enabled.&nbsp;
            {grant
              ? <span>To do this,
                click <a href="https://marketplace.zoom.us/user/installed" target="_blank" rel="noreferrer">
                  this link to view your installed apps
              </a>, find Cabinet in the list, and click
                "update" as shown in the image below:
              </span>
              : <span>On the next screen, please <b>make sure to check the box</b> indicated in the image below:</span>
            }
          </DialogContentText>
          <img
            className={classes.sharedAccessPermissionImg}
            src={grant ? ZOOM_SHARED_ACCESS_PERMISSIONS_UPDATE : ZOOM_SHARED_ACCESS_PERMISSIONS}
            alt="shared access permission checkbox"
          />
          {grant && (
            <DialogContentText>
              On the pop-up that opens, check the box next to "Allow this app..." and then click "Authorize".
            </DialogContentText>
          )}
          <DialogContentText>
            If you don't wish to schedule on behalf of others via Zoom Schedulers, you can close this window and use
            the Personal Meeting Link for each executive. For any questions, email&nbsp;
            <a href="mailto: support@joincabinet.com">support@joincabinet.com</a>
          </DialogContentText>
        </Box>
      </CabModal>
    </>
  );
};

export default ZoomSignIn;
